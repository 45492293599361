import { useDispatch, useSelector } from 'react-redux';
import { ContractBasedStateSelector } from './createContractBasedStateSelector';
import { useMemoizedSelector } from './useMemoizedSelector';
import { AbstractContractBasedDataState } from './contractBasedDataFetchSlice';

export type ContractBasedUpdateStateType<TAppThunk, TBusinessObject, TAppErrorCode extends string> = {
    updateState: (data: AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>) => TAppThunk;
    state: AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>;
};

/**
 * Updates whole contract-based state
 */
export function useGetContractBasedUpdateState<TAppThunk, TBusinessObject, TState, TAppErrorCode extends string>(
    updateState: (state: AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>) => TAppThunk,
    selector: ContractBasedStateSelector<TState, TBusinessObject, TAppErrorCode>,
): ContractBasedUpdateStateType<TAppThunk, TBusinessObject, TAppErrorCode> {
    const dispatch = useDispatch();
    const memoizedExistingDataSelector = useMemoizedSelector(selector);
    const existingData = useSelector(state => memoizedExistingDataSelector(state as TState));
    return {
        updateState: (state: AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>): TAppThunk =>
            dispatch(updateState(state)),
        state: existingData,
    };
}
