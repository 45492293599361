import { AxiosRequestConfig } from 'axios';
import { AbstractDataState } from './AbstractDataState';
import { useGetContractBasedApiData } from './useContractBasedApiData';
import { useMemo } from 'react';
import { ContractBasedDataSelector } from './createContractBasedDataSelector';
import { useMemoizedSelector } from './useMemoizedSelector';

export function useGetContractBasedApiDataWithTransformer<
    TState,
    TAppThunk,
    TBusinessObject,
    TAppErrorCode extends string,
    TTransformedBusinessObject
>(
    contractId: string,
    fetchData: (contractId: string, link?: string, requestConfig?: AxiosRequestConfig) => TAppThunk,
    existingDataSelector: ContractBasedDataSelector<TState, TBusinessObject, TAppErrorCode>,
    dataMapper: (data?: TBusinessObject) => TTransformedBusinessObject,
    link?: string,
    alwaysReFetch = false,
    requestConfig?: AxiosRequestConfig,
): AbstractDataState<TTransformedBusinessObject, TAppErrorCode> {
    const memoizedExistingDataSelector = useMemoizedSelector(existingDataSelector);

    const existingData = useGetContractBasedApiData(
        contractId,
        fetchData,
        memoizedExistingDataSelector,
        link,
        alwaysReFetch,
        requestConfig,
    );

    return useMemo(() => ({ ...existingData, data: dataMapper(existingData.data) }), [dataMapper, existingData]);
}
