import { createSelector, OutputSelector } from '@reduxjs/toolkit';
import { AbstractContractBasedDataState } from './contractBasedDataFetchSlice';

export type ContractBasedStateSelector<TState, TBusinessObject, TAppErrorCode extends string> = OutputSelector<
    TState,
    AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>,
    (
        res: AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>,
    ) => AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>
>;

export function getContractBasedStateSelector<TState, TBusinessObject, TAppErrorCode extends string>(
    existingStateSelector: (state: TState) => AbstractContractBasedDataState<TBusinessObject, TAppErrorCode>,
): ContractBasedStateSelector<TState, TBusinessObject, TAppErrorCode> {
    return createSelector(existingStateSelector, existingState => existingState);
}
