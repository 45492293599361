// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function trimAllValues<Type>(inputValue?: Type & (object | any[])): Type {
    if (inputValue === null || (!Array.isArray(inputValue) && typeof inputValue != 'object')) return inputValue as Type;
    return Object.keys(inputValue).reduce(
        function(value, key) {
            const keyValue = inputValue[key];
            value[key] =
                typeof keyValue == 'string' ? inputValue[key].trim() : trimAllValues<typeof keyValue>(keyValue);
            return value;
        },
        Array.isArray(inputValue) ? [] : {},
    ) as Type;
}
